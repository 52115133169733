import Carousel from "./carousel";
import type { SocialPost } from "@prisma/client";
import { useState } from "react";

type Props = {
  slides: Pick<SocialPost, "id" | "caption" | "url" | "imgSrc" | "colorId">[];
};

export default function InstagramCarousel({ slides }: Props) {
  const [error, setError] = useState(false);

  return (
    <Carousel cols={4} spacing={false}>
      {slides.map(({ url, caption, id, imgSrc }) => (
        <a
          key={id}
          href={url}
          rel="noreferrer"
          target="_blank"
          className="relative flex justify-center items-center group text-center bg-secondary text-white"
          style={{ height: "300px" }}
        >
          {error ? (
            <div
              className="p-6"
              dangerouslySetInnerHTML={{ __html: caption }}
            />
          ) : (
            <>
              <img
                loading="lazy"
                decoding="async"
                style={{ opacity: 0 }}
                className="transition-opacity duration-200 ease-in"
                src={imgSrc}
                alt="Image from Radikal Signs Instagram"
                onError={() => setError(true)}
                onLoad={(ref) => {
                  ref.currentTarget.style.opacity = "100";
                }}
              />
              <div className="absolute h-full w-full bg-black opacity-0 group-hover:opacity-75 transition-opacity duration-200" />
              <div
                className="absolute h-full w-full flex justify-center items-center text-center p-6 opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                dangerouslySetInnerHTML={{ __html: caption }}
              />
            </>
          )}
        </a>
      ))}
    </Carousel>
  );
}
